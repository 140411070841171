import Cookies from "js-cookie";

const get = function (name: string) {
  return Cookies.get(name);
};

const set = function (key: string, value: string, options: object | undefined) {
  Cookies.set(key, value, options);
};

const remove = function (key: string) {
  Cookies.remove(key);
};

const reset = function () {
  localStorage.removeItem("base_api");
  Cookies.remove("access_token");
};

export default { get, set, remove, reset };
